.juries {
  background: #0e1d2a;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: stretch;

  padding-top: 3%;
}

.juries .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@media (max-width: 679px) {
  .juries .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.juries .container .desc {
  max-width: 40%;
}

@media (max-width: 768px) {
  .juries .container .desc {
    max-width: 37%;
  }
}

@media (max-width: 576px) {
  .juries .container .desc {
    max-width: 100%;
  }
}

.juries .container .desc .jury-title {
  max-width: 430px;
  margin-bottom: 22px;
}

.juries .container .images {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 442px;
  max-width: 100%;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .juries .container .images {
    width: 342px;
  }
}

@media (max-width: 576px) {
  .juries .container .images {
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 48px;
  }
}

.juries-bg {
  padding: 0 5% 0 5%;
  background-image: url("../../components/images/lines2.svg") !important;
  background: #0e1d2a;
  height: 100%;
  background-size: 100% 50%;
  background-repeat: no-repeat;
  background-position: top right;
}

.headerChallengeJury {
  margin-bottom: 254px;
  max-width: 40%;
}

@media (max-width: 576px) {
  .headerChallengeJury {
    margin-bottom: 0px;
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .headerChallengeJury {
    margin-bottom: 0px;
    margin-top: 20px;
  }
}

.headerChallengeJury .chall-desc-jury {
  max-width: 100%;
  padding-bottom: 18px;
}

@media (max-width: 576px) {
  .headerChallengeJury .chall-desc-jury {
    max-width: 100%;
    padding-bottom: 11px;
  }
}

.headerChallengeJury .chall-desc-jury .spanChallJury {
  border-bottom: 11px solid #fff;
  position: relative;
}

@media (max-width: 768px) {
  .headerChallengeJury .chall-desc-jury .spanChallJury {
    border-bottom: 7px solid #fff;
  }
}

@media (max-width: 576px) {
  .headerChallengeJury .chall-desc-jury .spanChallJury {
    border-bottom: 4px solid #fff;
  }
}

@media (max-width: 1200px) {
  .headerChallengeJury .cardContainerJury {
    top: 96px;
    left: 24px;
  }
}

@media (max-width: 929px) {
  .headerChallengeJury .cardContainerJury {
    top: 79px;
    left: 15px;
  }
}

@media (max-width: 650px) {
  .spanChallJury {
    border: 0 !important;
    font-size: 32px;
  }
}

@media (max-width: 768px) {
  .headerChallengeJury .cardContainerJury {
    top: 51px;
    left: 10px;
  }
}

@media (max-width: 576px) {
  .headerChallengeJury .cardContainerJury {
    top: 33px;
    left: -2px;
  }
}

@media (max-width: 454px) {
  .headerChallengeJury .cardContainerJury {
    top: 33px;
    left: -2px;
  }
}

.box-jury {
  margin: auto;
  overflow: hidden;
  width: 300px;
  position: absolute;
  top: 78px;
  left: 0px;
}

#mascote {
  margin: auto;
  position: relative;
  top: -200px;
  transition: top 1s;
}

.spanChallJury:hover #mascote {
  top: 0px;
}

@media (max-width: 1200px) {
  .box-jury {
    top: 54px;
    left: 0px;
  }
}

@media (max-width: 1000px) {
  .box-jury {
    top: 50px;
    left: 0px;
  }
}

@media (max-width: 770px) {
  .box-jury {
    top: 31px;
    left: 0px;
  }
}

@media (max-width: 650px) {
  .box-jury {
    overflow: hidden;
  }
  #mascote {
    display: none;
  }
}
