.ready .container .details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.ready .container .details .expect {
  max-width: 300px;
}

.ready .container .details .quote {
  max-width: 438px;
}

@media (max-width: 1200px) {
  .ready .container .description {
    max-width: 603px;
  }
  .ready .container .details .quote {
    max-width: 321px;
  }
}

@media (max-width: 992px) {
  .ready .container .description {
    max-width: 583px;
  }
  .ready .container .details .expect {
    max-width: 246px;
  }
  .ready .container .details .quote {
    max-width: 282px;
  }
}

@media (max-width: 768px) {
  .ready .container .description {
    max-width: 436px;
  }
  .ready .container .details .expect {
    max-width: 150px;
  }
  .ready .container .details .quote {
    max-width: 245px;
  }
}

@media (max-width: 576px) {
  .ready .container .details {
    margin-top: 70px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .ready .container .details .expect {
    max-width: 100%;
  }
  .ready .container .details .quote {
    margin-top: 40px;
    max-width: 100%;
  }
}

.ready-register h1 {
  font-size: 64px;
  max-width: 840px;
  position: relative;
}

.ready-register h1 .span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ready-register h1 .span .button {
  margin-right: 20px;
}

.ready-register h1 .span .people {
  font-family: "Inter Regular";
}

@media (max-width: 1200px) {
  .ready-register h1 .span .button {
    padding: 11px 26px;
    margin-right: 12px;
  }
  .ready-register h1 .span .people {
    font-size: 12px;
  }
}

@media (max-width: 1200px) {
  .ready-register h1 .span .button {
    padding: 11px 26px;
    margin-right: 12px;
  }
  .ready-register h1 .span .people {
    font-size: 12px;
  }
}

@media (max-width: 992px) {
  .ready-register h1 {
    max-width: 700px;
    font-size: 54px;
  }
  .ready-register h1 .span .button {
    margin-right: 8px;
  }
}

@media (max-width: 768px) {
  .ready-register h1 {
    font-size: 34px;
  }
}

.ready-bg {
  padding: 0 5% 0 5%;
  background: #0e1d2a;
  height: 100%;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: top right;
}

.ready .container {
  max-width: 87%;
  width: 100%;
  max-width: 1440px;
  padding-left: 5vw;
  padding-right: 5vw;
}
