* {
  margin: 0px;
  padding: 0px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0;
  vertical-align: baseline;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */
}

body {
  overflow-x: hidden;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  display: block;
  font-weight: 700;
}

i {
  vertical-align: middle;
}

a {
  text-decoration: none;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

svg path {
  -webkit-transition: stroke 300ms ease-in-out, fill 300ms ease-in-out;
  transition: stroke 300ms ease-in-out, fill 300ms ease-in-out;
}

svg:hover path {
  -webkit-transition: stroke 300ms ease-in-out, fill 300ms ease-in-out;
  transition: stroke 300ms ease-in-out, fill 300ms ease-in-out;
}

input,
textarea:focus {
  outline: 0;
}

button:focus {
  cursor: pointer;
  outline: 0;
}

ol,
ul {
  list-style: none;
}

li {
  list-style: none;
}

/**
* Remove most spacing between table cells.
*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */
}

/*=== Import Fonts ===*/
@font-face {
  font-family: "Inter Regular";
  src: url("../fonts/Inter/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter Black";
  src: url("../fonts/Inter/Inter-Black.ttf") format("truetype");
}

/*=== Typography Styling ===*/
body {
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

a:visited {
  text-decoration: none;
}

h1 {
  font-family: "Inter Black";
  font-size: 92px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

@media (max-width: 1200px) {
  h1 {
    font-size: 70px;
  }
}

@media (max-width: 992px) {
  h1 {
    font-size: 56px;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 36px;
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 32px;
  }
}

h3 {
  font-family: "Inter Black";
  font-size: 56px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

@media (max-width: 1200px) {
  h3 {
    font-size: 36px;
  }
}

@media (max-width: 992px) {
  h3 {
    font-size: 32px;
  }
}

@media (max-width: 768px) {
  h3 {
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  h3 {
    font-size: 16px;
  }
}

h2 {
  font-family: "Inter Black";
  font-size: 56px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

h5 {
  font-family: "Inter Black";
  font-size: 36px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

h4 {
  font-family: "Inter Black";
  font-size: 36px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

h6 {
  font-family: "Inter Black";
  font-size: 30px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

p.x-large-1 {
  font-family: "Inter Black";
  font-size: 28px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #4ca9ff;
}

@media (max-width: 1200px) {
  p.x-large-1 {
    font-size: 32px;
  }
}

@media (max-width: 992px) {
  p.x-large-1 {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  p.x-large-1 {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  p.x-large-1 {
    font-size: 12px;
  }
}

p.x-large {
  font-family: "Inter Black";
  font-size: 32px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #00ffb7;
}

p.large {
  font-family: "Inter Regular";
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #fefcfb;
}

p.parag {
  font-family: "Inter Regular";
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

@media (max-width: 1200px) {
  p.parag {
    font-size: 16px;
  }
}

@media (max-width: 992px) {
  p.parag {
    font-size: 12px;
  }
}

p.medium-4 {
  font-family: "Inter Black";
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #181818;
}

p.medium-3 {
  font-family: "Inter Black";
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

@media (max-width: 992px) {
  p.medium-3 {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  p.medium-3 {
    font-size: 9px;
  }
}

p.medium-2 {
  font-family: "Inter Regular";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #181818;
}

@media (max-width: 992px) {
  p.medium-2 {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  p.medium-2 {
    font-size: 12px;
  }
}

p.medium {
  font-family: "Inter Regular";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

p.medium-5 {
  font-family: "Inter Black";
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: 0.32px;
  text-align: left;
  color: rgba(255, 255, 255, 0.4);
}

@media (max-width: 992px) {
  p.medium-5 {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  p.medium-5 {
    font-size: 12px;
  }
}

p.small {
  font-family: "Inter Regular";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

p.x-small {
  font-family: "Inter Black";
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: right;
  color: #ffffff;
}

p.x_small2 {
  font-family: "Inter Regular";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

@media (max-width: 576px) {
  p.x_small2 {
    font-size: 10px;
  }
}

p.x-small3 {
  font-family: "Inter Regular";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 255, 183, 0.5);
}

.nav-bar-link {
  font-family: "Inter Regular";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

@media (max-width: 576px) {
  .nav-bar-link {
    font-size: 10px;
  }
}

button {
  font-family: "Inter Regular";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: 0.72px;
  text-align: center;
  color: #ffffff;
}

@media (max-width: 1200px) {
  button {
    font-size: 10px;
  }
}

.menu-icon-wrapper {
  position: relative;
  left: 0;
  top: 0;
  width: 34px;
  height: 34px;
  pointer-events: none;
  -webkit-transition: 400ms ease-in-out;
  transition: 400ms ease-in-out;
  z-index: 9;
}

.menu-icon-wrapper svg {
  position: absolute;
  top: -18px;
  left: -18px;
  -webkit-transform: scale(0.07);
  transform: scale(0.07);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.menu-icon-wrapper svg path {
  stroke: #fff;
  stroke-width: 40px;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: transparent;
  -webkit-transition: stroke-dasharray 0.5s;
  transition: stroke-dasharray 0.5s;
}

.menu-icon-wrapper svg path.path1 {
  stroke-dashoffset: 5803.15px;
  stroke-dasharray: 2901.57px, 2981.57px, 300px;
}

.menu-icon-wrapper svg path.path2 {
  stroke-dashoffset: 800px;
  stroke-dasharray: 400px, 480px, 200px;
}

.menu-icon-wrapper svg path.path3 {
  stroke-dashoffset: 6993.11px;
  stroke-dasharray: 3496.56px, 3685.56px, 130px;
}

.menu-icon-wrapper.open svg path.path1 {
  stroke-dasharray: 2901.57px, 5258.15px, 240px;
}

.menu-icon-wrapper.open svg path.path2 {
  stroke-dasharray: 400px, 600px, 0px;
}

.menu-icon-wrapper.open svg path.path3 {
  stroke-dasharray: 3496.56px, 6448.11px, 240px;
}

.menu-icon-wrapper .menu-icon-trigger {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  pointer-events: auto;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  outline: 0;
}

.menu-icon-wrapper .menu-icon-trigger:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.navbar {
  height: 20vh;
  -webkit-transition: -webkit-box-shadow 300ms ease-in-out;
  transition: -webkit-box-shadow 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out, -webkit-box-shadow 300ms ease-in-out;
}

.navbar .container-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar .container-nav .brand {
  margin-right: 48px;
}

.navbar .container-nav .nav-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 30px;
}

.navbar .container-nav .nav-content .nav-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  padding-top: 18px;
  padding-right: 20px;
}

.navbar .container-nav .nav-content .nav-item .nav-link {
  padding: 10px 25px 10px 25px;
  opacity: 0.4;
}

.navbar .container-nav .nav-content .nav-item .nav-link:hover,
.navbar .container-nav .nav-content .nav-item .nav-link.active {
  color: #fff;
  opacity: 1;
}

.navbar .container-nav .nav-content .nav-item .nav-link .pt {
  color: rgba(255, 255, 255, 0.3);
}

.navbar .container-nav .pt-icon {
  margin-left: 61%;
  display: none;
}

.navbar .container-nav .pt-icon.is-show {
  display: block;
}

@media (max-width: 768px) {
  .navbar {
    width: 90%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .navbar .container-nav {
    position: relative;
    display: block;
  }
  .navbar .container-nav .brand {
    margin: 0 auto;
  }
  .navbar .container-nav .logo {
    padding-right: 5%;
    -webkit-transition: left 400ms ease-in;
    transition: left 400ms ease-in;
    position: relative;
    z-index: 4;
  }
  .navbar .container-nav .nav-content {
    background: #000;
    position: fixed;
    top: 0;
    right: -900px;
    bottom: 0;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: right 400ms ease-in;
    transition: right 400ms ease-in;
    z-index: 3;
  }
  .navbar .container-nav .nav-content.is-active {
    right: 0;
    -webkit-transition: right 400ms ease-out;
    transition: right 400ms ease-out;
  }
  .navbar .container-nav .nav-content > * {
    width: 100%;
    text-align: center;
  }
  .navbar .container-nav .nav-content .nav-link {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .navbar .container-nav .nav-content .nav_link-s {
    font-family: "Inter Black";
    font-size: 30px;
    font-weight: 900;
    line-height: 2;
  }
  .navbar .container-nav .nav-content .lang {
    display: none;
  }
  .navbar .container-nav .nav-content .join-us {
    display: none;
  }
  .navbar .container-nav .menu-icon-wrapper {
    display: block !important;
    position: absolute;
    top: -4px;
    left: 97%;
  }
  .navbar .container-nav .nav-content .nav-item .nav-link {
    padding: 16px;
    opacity: 1;
  }
}
/*# sourceMappingURL=navbar.css.map */
