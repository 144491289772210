.mentors {
  background: #0e1d2a;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: stretch;

  padding-top: 3%;
}

.mentors .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@media (max-width: 679px) {
  .mentors .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.mentors .container .desc {
  max-width: 40%;
}

@media (max-width: 768px) {
  .mentors .container .desc {
    max-width: 37%;
  }
  .mentors .container .desc .mentor-title {
    font-size: 32px;
  }
}

@media (max-width: 576px) {
  .mentors .container .desc {
    max-width: 100%;
  }
  .mentors .container .desc .mentor-title {
    font-size: 32px;
  }
}

.mentors .container .desc .mentor-title {
  max-width: 430px;
  margin-bottom: 22px;
}

.mentors .container .images {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 442px;
  max-width: 100%;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .mentors .container .images {
    width: 342px;
  }
}

@media (max-width: 576px) {
  .mentors .container .images {
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 48px;
  }
}

.mentors-bg {
  padding: 0 5% 0 5%;
  background-image: url("../../components/images/lines2.svg") !important;
  background: #0e1d2a;
  height: 100%;
  background-size: 100% 50%;
  background-repeat: no-repeat;
  background-position: top right;
}
