.error-bg {
  padding: 0 5% 0 5%;
  background-image: url("../../components/images/lines3.svg") !important;
  background: #181818;
  height: 100%;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error .container {
  padding-top: 3%;
  padding-bottom: 5%;
  max-width: 87%;
  width: 100%;
  max-width: 1440px;
  padding-left: 5vw;
  padding-right: 5vw;
}

.error .landing {
  display: flex;
  flex-direction: column;
  padding-top: 1%;
  padding-bottom: 5%;
  width: 100%;
}

.error .container .description {
  margin-top: auto;
  max-width: 792px;
  height: 60vh;
}

h1.error-type {
  font-family: "Inter Black";
  font-size: 92px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: rgb(79, 177, 255);
}

.mascote {
  position: fixed;
  bottom: 0px;
  height: 600px;
  width: 600px;
  left: 60%;
}

@media (max-width: 1200px) {
  .home .container .description {
    max-width: 603px;
  }
}

@media (max-width: 768px) {
  h1.error-type {
    font-size: 62px;
  }
  .mascote {
    height: 350px;
    width: 500px;
    left: 50%;
    -ms-transform: translate(-50%, 0%);
    -moz-transform: translate(-50%, 0%);
    -o-transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
  }
  .error .container .description {
    max-width: 436px;
  }
}

@media (max-width: 576px) {
  h1.error-type {
    font-size: 50px;
  }
  .mascote {
    height: 250px;
    width: 250px;
    -ms-transform: translate(-50%, 0%);
    -moz-transform: translate(-50%, 0%);
    -o-transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
  }
  .error .container .description {
    max-width: 330px;
  }
  .error .container .description h1 {
    margin-top: 2%;
  }
}

@media (max-width: 375px) {
  h1.error-type {
    font-size: 25px;
  }
}

.back {
  margin-top: 30px;
}
