.badge-card {
  padding: 10px;
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: darkgray;
  border-radius: 10%;
  height: 100%;
}

.badge-card:hover {
  background-color: rgba(1, 1, 1, 0.04);
  cursor: pointer;
}

.owned {
  color: #00ffb7;
}

.dropdown {
  padding: 8px;
  border-radius: 40px;
}

.badge-info {
  display: flex;
  justify-content: center;
  justify-items: center;
  /* margin: auto; */
  flex-direction: column;
}

.badge-info .big {
  height: 350px;
  width: 350px;
  margin: auto;
}

.badge-info .desc {
  font-size: 18px;
  font-family: "Inter Regular";
  margin: 20px auto;
}

.badge-card .owned {
  height: 150px;
  width: 150px;
}

.badge-card .normal {
  height: 150px;
  width: 150px;
  opacity: 0.2;
}

.badge-card .name {
  margin-top: 8px;
  font-size: 24px;
  font-family: "Inter Black";
}

.badge-card .id {
  font-size: 16px;
  font-family: "Inter Regular";
}

.badge-card .center-image {
  margin: auto;
}

.owners {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px;
  justify-content: flex-start;
  width: 100%;
  height: 50vh;
  overflow-y: scroll;
}

/* SCROLLBAR */
.owners::-webkit-scrollbar {
  width: 10px;
}

.owners::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.owners::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.owners::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/**/

.owner {
  display: flex;
  grid-column: span 6;
}

.owner-name {
  font-family: "Inter Regular";
  font-weight: bold;
  letter-spacing: 0.5px;
}

.filters {
  display: flex;
  margin-top: 37px;
  width: 100%;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .filters {
    flex-direction: column;
  }
}

.filters .bold {
  font-family: "Inter Black";
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  font-size: 24px;
  font-weight: bold;
}

.flex {
  display: flex;
  align-items: center;
  align-content: center;
}

.flex * {
  margin: 8px;
}

/* GRID */
.badgedex {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px;
  width: 100%;
}

.col-xs-12 {
  grid-column: span 12;
}

.col-xs-11 {
  grid-column: span 11;
}

.col-xs-10 {
  grid-column: span 10;
}

.col-xs-9 {
  grid-column: span 9;
}

.col-xs-8 {
  grid-column: span 8;
}

.col-xs-7 {
  grid-column: span 7;
}

.col-xs-6 {
  grid-column: span 6;
}

.col-xs-5 {
  grid-column: span 5;
}

.col-xs-4 {
  grid-column: span 4;
}

.col-xs-3 {
  grid-column: span 3;
}

.col-xs-2 {
  grid-column: span 2;
}

.col-xs-1 {
  grid-column: span 1;
}

@media (min-width: 768px) {
  .col-sm-12 {
    grid-column: span 12;
  }

  .col-sm-11 {
    grid-column: span 11;
  }

  .col-sm-10 {
    grid-column: span 10;
  }

  .col-sm-9 {
    grid-column: span 9;
  }

  .col-sm-8 {
    grid-column: span 8;
  }

  .col-sm-7 {
    grid-column: span 7;
  }

  .col-sm-6 {
    grid-column: span 6;
  }

  .col-sm-5 {
    grid-column: span 5;
  }

  .col-sm-4 {
    grid-column: span 4;
  }

  .col-sm-3 {
    grid-column: span 3;
  }

  .col-sm-2 {
    grid-column: span 2;
  }

  .col-sm-1 {
    grid-column: span 1;
  }
}

@media (min-width: 992px) {
  .col-md-12 {
    grid-column: span 12;
  }

  .col-md-11 {
    grid-column: span 11;
  }

  .col-md-10 {
    grid-column: span 10;
  }

  .col-md-9 {
    grid-column: span 9;
  }

  .col-md-8 {
    grid-column: span 8;
  }

  .col-md-7 {
    grid-column: span 7;
  }

  .col-md-6 {
    grid-column: span 6;
  }

  .col-md-5 {
    grid-column: span 5;
  }

  .col-md-4 {
    grid-column: span 4;
  }

  .col-md-3 {
    grid-column: span 3;
  }

  .col-md-2 {
    grid-column: span 2;
  }

  .col-md-1 {
    grid-column: span 1;
  }
}

@media (min-width: 1200px) {
  .col-lg-12 {
    grid-column: span 12;
  }

  .col-lg-11 {
    grid-column: span 11;
  }

  .col-lg-10 {
    grid-column: span 10;
  }

  .col-lg-9 {
    grid-column: span 9;
  }

  .col-lg-8 {
    grid-column: span 8;
  }

  .col-lg-7 {
    grid-column: span 7;
  }

  .col-lg-6 {
    grid-column: span 6;
  }

  .col-lg-5 {
    grid-column: span 5;
  }

  .col-lg-4 {
    grid-column: span 4;
  }

  .col-lg-3 {
    grid-column: span 3;
  }

  .col-lg-2 {
    grid-column: span 2;
  }

  .col-lg-1 {
    grid-column: span 1;
  }
}

@media (min-width: 1600px) {
  .col-xl-12 {
    grid-column: span 12;
  }

  .col-xl-11 {
    grid-column: span 11;
  }

  .col-xl-10 {
    grid-column: span 10;
  }

  .col-xl-9 {
    grid-column: span 9;
  }

  .col-xl-8 {
    grid-column: span 8;
  }

  .col-xl-7 {
    grid-column: span 7;
  }

  .col-xl-6 {
    grid-column: span 6;
  }

  .col-xl-5 {
    grid-column: span 5;
  }

  .col-xl-4 {
    grid-column: span 4;
  }

  .col-xl-3 {
    grid-column: span 3;
  }

  .col-xl-2 {
    grid-column: span 2;
  }

  .col-xl-1 {
    grid-column: span 1;
  }
}
