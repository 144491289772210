.activity:hover {
  background-color: #4ca9ff;
}

.activity:hover .medium-3 {
  color: #181818;
}

.activity:hover .medium {
  color: #181818;
}

.activity:hover .button {
  border: 2px solid #ffff !important;
}

.agenda-link {
  font-family: "Inter Regular";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  text-decoration: "none";
  display: block;
}

.activity-type {
  font-family: "Inter Black";
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

@media (max-width: 992px) {
  .activity-type {
    font-size: 14px;
  }
}

p.activity {
  font-family: "Inter Regular";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}
