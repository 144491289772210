.places {
  display: flex;
}

.section {
  flex: 1;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .places {
    flex-direction: column;
  }
}

h3.section-title {
  color: #181818;
  margin-bottom: 5%;
  text-align: center;
}

h6 {
  color: #181818;
  text-align: center;
  margin-bottom: 25px;
}

.hackathonplace {
  font-family: "Inter Black";
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  color: #181818;
  text-align: center;
  margin-bottom: 10px;
}

.containerprofile .userProfile .main.awards {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
}

.awardsList {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: stretch;
}

.awardItem {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
}

@font-face {
  font-family: "Inter SemiBold";
  src: url("../fonts/Inter/Inter-SemiBold.ttf") format("truetype");
}

.awardName {
  margin-top: 15px;
  font-size: 16px;
  font-family: "Inter SemiBold";
}

@media (max-width: 1600px) {
  .awardItem {
    margin-right: 35px;
  }
}

.awardItem img {
  height: 250px;
}

@media (max-width: 1600px) {
  .awardItem {
    width: 45%;
  }
}

.fixedWheel .wheel {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background: url("../img/Wh.png") center no-repeat;
}

.fixedWheel .union {
  width: 12px;
  height: 20px;
  background: url("../img/Union.svg") center no-repeat;
  background-size: 100%;
  position: absolute;
  top: 35%;
  left: 48.5%;
}

@media (max-width: 992px) {
  .fixedWheel .union {
    top: 34%;
    left: 48%;
  }
}

@media (max-width: 576px) {
  .fixedWheel .union {
    top: 33%;
    left: 47.5%;
  }
}

@media (max-width: 576px) {
  .awardItem .buttonText {
    padding-top: 8px;
    padding-bottom: 8px;
    width: 96px;
  }
}

.stock {
  font-family: Inter Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: center;
  margin-bottom: 5px;
}

.limit {
  font-family: Inter Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: center;
}

.awardItem[disabled] {
  pointer-events: none;
  opacity: 0.5;
}
