* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  border: 0;
  vertical-align: baseline;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */
}

body {
  overflow-x: hidden;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  display: block;
  font-weight: 700;
}

i {
  vertical-align: middle;
}

a {
  text-decoration: none;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

svg path {
  transition: stroke 300ms ease-in-out, fill 300ms ease-in-out;
}

svg:hover path {
  transition: stroke 300ms ease-in-out, fill 300ms ease-in-out;
}

input,
textarea:focus {
  outline: 0;
}

button:focus {
  cursor: pointer;
  outline: 0;
}

ol,
ul {
  list-style: none;
}

li {
  list-style: none;
}

/**
* Remove most spacing between table cells.
*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */
}

/*=== Import Fonts ===*/
@font-face {
  font-family: "Inter Regular";
  src: url("../fonts/Inter/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter Black";
  src: url("../fonts/Inter/Inter-Black.ttf") format("truetype");
}

/*=== Typography Styling ===*/
body {
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

a {
  cursor: pointer;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.moonstone-title {
  font-family: "Inter Black";
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
}

.header-1 {
  line-height: 1;
  font-size: 36px;
  color: #000000;
}

.header-2 {
  line-height: 2;
  font-size: 30px;
  color: #ffffff;
}

.header-3 {
  font-size: 16px;
  line-height: 1.1;
  text-align: left;
  color: #000000;
}

.header-4 {
  font-family: "Inter Regular";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.achievements {
  font-family: "Inter Regular";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.winnerName {
  font-family: "Inter Black";
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.timing {
  font-family: "Inter Black";
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: right;
  color: var(--mint);
}

.award {
  font-family: "Inter Regular";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.buttonInner {
  font-family: "Inter Regular";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}

.buttonText {
  font-family: "Inter Regular";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: 0.72px;
  text-align: center;
  color: #000000;
}

label {
  font-family: "Inter Regular";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: 0.72px;
  text-align: left;
  color: #ffffff;
}

.label {
  font-family: "Inter Regular";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: 0.72px;
  text-align: left;
  color: #ffffff;
}

.small {
  font-family: "Inter Regular";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
}

.textError {
  font-family: "Inter Regular";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #ff4444;
}

.buttonText {
  /* background-color: #00ffb7; */
  display: inline-block;
  position: relative;
  overflow: hidden;
  padding-top: 18px;
  padding-bottom: 18px;
  border-radius: 40px;
  cursor: pointer;
}

.buttonText::before {
  content: "";
  position: absolute;
  background-color: #ffffff;
  padding: 50%;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(2);
  opacity: 0;
  transition: transform 700ms, opacity 700ms;
  pointer-events: none;
}

.buttonText:active::before {
  transition: 0s;
  opacity: 1;
  transform: translate(-50%, -50%) scale(0);
}

.input-container input {
  width: 340px;
  height: 40px;
  padding: 13.6px 70.2px 13.6px 30.2px;
  border-radius: 40px;
  border: solid 1px rgba(255, 255, 255, 0.1);
  background-color: #1d1d1d;
  color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 766px) {
  .input-container input {
    width: 250px;
    height: 34px;
    padding: 13.6px 70.2px 13.6px 22.2px;
  }
}

.input-container .label {
  margin-bottom: 7px;
  margin-left: 30.2px;
}

@media (max-width: 766px) {
  .input-container .label {
    font-size: 10px;
    line-height: 1;
    margin-left: 22.2px;
  }
}

.input-container .textError {
  margin-left: 30.2px;
  margin-bottom: 19px;
}

@media (max-width: 766px) {
  .input-container .textError {
    margin-left: 22.2px;
    margin-top: 10px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
  margin-bottom: 37px;
  align-items: flex-end;
  align-self: flex-start;
}

@media (max-width: 576px) {
  .header {
    padding-bottom: 12px;
  }
}

.header h3 {
  margin-right: 5px;
}

@media (max-width: 576px) {
  .header h3 {
    font-size: 14px;
  }
}

.header-section h1 {
  text-align: left;
  margin-bottom: 7px;
}

@media (max-width: 766px) {
  .header-section h1 {
    font-size: 27px;
    margin-bottom: 4px;
  }
}

@media (max-width: 766px) {
  .header-section h4 {
    font-size: 14px;
  }
}

.card-container {
  display: flex;
  align-items: flex-start;
}

@media (max-width: 766px) {
  .card-container img {
    width: 60px;
    height: 83px;
  }
}

.card-container .card {
  width: 244px;
  height: 59px;
  padding: 12px;
  background-color: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  margin-left: 12px;
}

@media (max-width: 766px) {
  .card-container .card {
    width: 212px;
    height: 52px;
    padding: 10px;
  }
}

.card-container .card h3 {
  font-family: "Inter Regular";
  color: #181818;
  font-weight: normal;
}

@media (max-width: 766px) {
  .card-container .card h3 {
    font-size: 14px;
  }
}

.winner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 3px;
}

.winner:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.winner .timing {
  color: #00ffb7;
  flex: 1;
}

.winner .winnerName {
  flex: 1;
  text-align: left;
}

.winner .award {
  flex: 1;
  text-align: left;
}

.latestWins {
  margin-top: 26.5px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  width: 50%;
}

.badge-owners {
  margin-top: 14px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  width: 50%;
}

.participant {
  display: flex;
  font-family: "Inter Regular";
  justify-content: space-between;
  align-items: center;
  padding: 19px 3px;
  cursor: pointer;
}

.participant:hover {
  background-color: rgb(245, 245, 245);
}

.participant:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.participant.selected {
  background-color: #00ffb7;
  cursor: initial;
}

.participant .participantName {
  flex: 1;
  text-align: left;
}

.participants {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  width: 50%;
}

@media (max-width: 1200px) {
  .participants {
    width: 90%;
  }
}

.sendCode {
  background-color: transparent;
  width: 256px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #00ffb7;
  border-radius: 40px;
}

@media (max-width: 576px) {
  .sendCode {
    width: 236px;
  }
}

.sendCode input {
  width: 130px;
  padding-left: 23px;
  text-align: left;
}

@media (max-width: 576px) {
  .sendCode .buttonText {
    padding-top: 16px;
    padding-bottom: 16px;
    width: 94px !important;
  }
}

.barItem {
  cursor: pointer;
  padding: 23px 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  background-color: "transparent";
}

.barItem .selected {
  color: #00ffb7;
}

.barItem.selected {
  background-color: #1d1d1d;
}

@media (max-width: 768px) {
  .barItem {
    border: 0;
  }
  .barItem .label {
    font-family: "Inter Black";
    font-size: 30px;
    text-align: center;
  }
  .barItem.selected {
    background-color: transparent;
  }
}

.sideBar {
  background-color: #181818;
  padding-top: 52px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
  min-width: 20%;
  position: relative;
}

.sideBar .sideBarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sideBar .sideBarHeader a.icon {
  display: flex;
  background: url("../img/menuIcon.svg") no-repeat center;
  width: 23px;
  height: 11px;
  background-size: 23px;
  display: none;
}

.sideBar .sideBarHeader a.pt {
  display: none;
}

.sideBar a,
.sideBar button {
  display: flex;
  color: #00ffb7;
  margin-bottom: 27px;
}

.sideBar a.logout,
.sideBar button.logout {
  margin-bottom: 52px;
}

.sideBar .logo {
  background: url("../img/logo/moonstoneLogo.svg") no-repeat center;
  width: 210px;
  height: 84px;
  background-size: 100%;
  margin-bottom: 59px;
}

.sideBar .company_name {
  font-family: "Inter Black";
  font-size: 40px;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  /* width: 210px; */
  min-height: 84px;
  margin-top: 59px;
}

@media (max-width: 1200px) {
  .sideBar .logo {
    width: 160px;
  }
  .sideBar .company_name {
    /* width: 160px; */
  }
}

@media (max-width: 768px) {
  .sideBar {
    padding-top: 32px;
    align-items: center;
    width: 100%;
    min-height: 100vh;
  }
  .sideBar .logo {
    margin: auto;
  }
  .sideBar .company_name {
    margin: auto;
    text-align: center;
  }
  .sideBar .sideBarHeader a {
    width: 100%;
    text-align: left;
    padding-left: 25px;
    padding-right: 25px;
  }
  .sideBar .sideBarHeader a.icon {
    position: relative;
    z-index: 4;
    display: flex;
  }
  .sideBar .sideBarHeader a.pt {
    display: flex;
    background: url("../img/Pt.svg") no-repeat center;
    width: 23px;
    height: 23px;
    background-size: 23px;
  }
  .sideBar .pagesLink {
    margin-bottom: 30px !important;
  }
}

.achievement {
  display: flex;
  padding-right: 20px;
}

.achievement .header-achiev {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 21px;
}

@media (min-width: 1200px) {
  .achievement .achievements {
    padding-top: 8px;
    font-size: 14px;
  }
}

@media (max-width: 1200px) {
  .achievement .achievements {
    padding-top: 8px;
    font-size: 14px;
    margin-bottom: 0px !important;
  }
}

@media (max-width: 992px) {
  .achievement .achievements {
    padding-top: 0 !important;
  }
}

.achiev-section {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  font-family: "Inter Regular";
}

@media (max-width: 1200px) {
  .achiev-section {
    width: 90%;
  }
}

.achiev-section .achiev-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 10px;
  width: 100%;
}

.achiev-section .achiev-desc {
  margin-top: 16px;
  max-width: 569px;
}

@media (max-width: 576px) {
  .achiev-section .achiev-desc {
    margin-top: 14px;
    max-width: 334px;
  }
}

@media (max-width: 576px) {
  .achiev-section .achiev-desc h4 {
    font-size: 14px;
    line-height: 1.2;
  }
}

.achiev-section h3 {
  padding-top: 12px;
}

.fileContainer {
  background: transparent !important;
  box-shadow: none !important;
}

.fileContainer .chooseFileButton {
  color: #181818 !important;
  background: #00ffb7 !important;
}

.fileContainer .deleteImage {
  background: #181818 !important;
}

.editProfile {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 8px;
}

@media (max-width: 1200px) {
  .editProfile {
    width: 90%;
  }
}

.editProfile .profilePicture {
  width: 89px;
  height: 89px;
  border-radius: 50%;
  border: 1px solid;
  margin-bottom: 32px;
}

@media (max-width: 576px) {
  .editProfile .profilePicture {
    width: 70px;
    height: 70px;
    background-size: contain;
    margin-bottom: 26px;
  }
}

.editProfile a.reset {
  color: #00ffb7;
  border-bottom: 1px solid #00ffb7;
  margin-left: 30.2px;
}

.editProfile .input-container:not(:last-of-type) {
  margin-bottom: 10px;
}

@media (max-width: 576px) {
  .editProfile .buttonText {
    width: 70px !important;
  }
}

.spinningWheel {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-left: 18px;
  margin-right: 18px;
  margin-bottom: 50px;
}

@media (max-width: 1200px) {
  .spinningWheel {
    width: 90%;
  }
}

.spinningWheel #wheel {
  width: 360px;
  height: 410px;
}

.spinningWheel [disabled] {
  width: 360px;
  height: 410px;
  pointer-events: none;
  opacity: 0.5;
}

.spinningWheel #wheel[disabled] {
  width: 360px;
  height: 410px;
}

.spinningWheel #wheel #canvas {
  width: 600px;
  height: 500px;
}

@media (max-width: 600px) {
  .spinningWheel {
    margin-left: 0;
    margin-right: 0;
  }

  .spinningWheel #wheel {
    width: 260px;
    height: 310px;
  }

  .spinningWheel #wheel #canvas {
    width: 450px;
    height: 380px;
  }
}

.spinningWheel .union {
  width: 16px;
  height: 28px;
  background: url("../img/Union.svg") center no-repeat;
  background-size: 100%;
  position: absolute;
  top: 47%;
  left: 48.5%;
}

@media (max-width: 992px) {
  .spinningWheel .union {
    top: 46.5%;
    left: 48.5%;
  }
}

@media (max-width: 576px) {
  .spinningWheel .union {
    top: 44.5%;
    left: 48%;
  }
}

@media (max-width: 576px) {
  .spinningWheel .buttonText {
    padding-top: 16px;
    padding-bottom: 16px;
    width: 188px;
  }
}

.containerregister {
  padding-top: 40px;
  position: relative;
  width: 100%;
}

@media (max-width: 1200px) {
  .containerregister {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 32px !important;
    padding-right: 32px !important;
    padding-top: 32px !important;
  }
}

.containerregister .form-container {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 766px) {
  .containerregister .form-container .logo {
    width: 196px;
    height: 77px;
  }
}

.containerregister .form-container .sign-up {
  color: #ffffff;
  margin-top: 16px;
  margin-bottom: 16px;
}

@media (max-width: 766px) {
  .containerregister .form-container .sign-up {
    font-size: 27px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.containerregister .form-container .login {
  color: #00ffb7;
  border-bottom: 1px solid #00ffb7;
}

.containerregister .form-container .login-here {
  margin-bottom: 26px;
  margin-top: 13px;
}

@media (max-width: 766px) {
  .containerregister .form-container .login-here {
    margin-top: 8px;
    font-size: 10px;
    line-height: 1;
  }
}

.containerregister .form-container .login-error {
  margin-bottom: 26px;
  margin-top: 13px;
  color: #ff4444;
}

@media (max-width: 766px) {
  .containerregister .form-container .login-error {
    margin-top: 8px;
    font-size: 10px;
    line-height: 1;
  }
}

.containerregister .form-container .button-reg {
  margin-top: 10px;
}

@media (max-width: 766px) {
  .containerregister .form-container .button-reg .buttonText {
    width: 250px !important;
    padding-top: 14px;
    padding-bottom: 14px;
    text-align: center;
  }
}

@media (max-width: 766px) {
  .containerregister .form-container .button-reg {
    margin-top: 10px;
  }
}

.containerregister .card-reg {
  position: absolute;
  right: 40px;
  bottom: 0;
}

@media (max-width: 1200px) {
  .containerregister .card-reg {
    position: relative;
    right: 0px;
  }
}

.containerregister .back {
  color: #00ffb7;
  align-self: flex-start;
}

@media (max-width: 766px) {
  .containerregister .back {
    font-size: 10px;
    line-height: 1;
  }
}

@media (max-height: 667px) {
  .bigContainer {
    height: 100% !important;
  }
}

.containerprofile {
  display: flex;
  padding-right: 0px;
  width: 100%;
}

.containerprofile .userProfile {
  overflow: auto;
  background-color: #ffffff;
  width: 100%;
  border-radius: 20px 0px 0px 20px;
  padding: 90px 90px 10px 72px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.containerprofile .userProfile .main {
  width: 100%;
  max-width: 1440px;
  margin-top: 37px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.containerprofile .userProfile .main .editProfile {
  margin-right: 18px;
  flex: 1;
}

.containerprofile .userProfile .main .achiev-section {
  flex: 1;
}

.containerprofile .userProfile .main .achiev-section .sendCode {
  margin-top: 20px;
}

.containerprofile .userProfile .main .achiev-section .sendCode input {
  border-radius: 10px;
}

@media (max-width: 1200px) {
  .containerprofile .main {
    flex-direction: column;
  }
  .containerprofile .main .editProfile {
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .containerprofile {
    padding-left: 0 !important;
  }
  .containerprofile .userProfile {
    border-radius: 0px;
  }
}

@media (max-width: 768px) {
  .containerprofile {
    position: relative;
  }
  .containerprofile .react-reveal {
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: scroll;
  }
  .containerprofile .react-reveal .userProfile {
    height: 100%;
    padding: 40px 10px 10px 30px;
    position: absolute;
    top: 8%;
    border-radius: 20px 20px 0 0;
  }
  .containerprofile .sideBarContainer {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .containerprofile .react-reveal .userProfile {
    height: 100%;
    padding: 40px 10px 10px 30px;
    top: 12%;
  }
}

.notification {
  border-radius: 15px !important;
}

/*# sourceMappingURL=style.css.map */

.rankcarousel {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 8px;
}

@media (max-width: 1200px) {
  .rankcarousel {
    width: 90%;
  }
}

.containerprofile .userProfile .leaderboard.main {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
}

.profile-achievs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
  align-content: stretch;
}
